import React, { useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listQueries } from "../../../../store/actions";
import Select from "../../../../UI/Form/Select/Select";
import { formatDataSourceFieldName } from "../../../../utils/dataSources";
import Flex from "../../../../UI/Flex/Flex";

export default function ActiveTableLoadFromDataSettings(props) {
  const dispatch = useDispatch();
  const queries = useSelector((state) => state.dataSettings.queries);
  const queriesLoading = useSelector(
    (state) => state.dataSettings.loading.queries
  );
  const { produceColumn, colDef, setColumnError } = props;

  useEffect(() => {
    dispatch(listQueries());
  }, [dispatch]);

  const queryUuid = colDef.cellEditorParams.loadFromQueryUuid;
  const fieldName = colDef.cellEditorParams.loadFromFieldName;

  const fieldOptions = useMemo(() => {
    const selectedQuery = queries?.find((v) => v.uuid === queryUuid);

    return (
      selectedQuery?.dataSources
        .map((v) => v.fields)
        .flat()
        .map((v) => ({
          value: v.name,
          label: formatDataSourceFieldName(v.mapping.displayName, v.name),
        })) ?? []
    );
  }, [queries, queryUuid]);

  const selectedFieldOption = useMemo(() => {
    return fieldOptions?.find((v) => v.value === fieldName);
  }, [fieldName, fieldOptions]);

  const setFieldName = useCallback(
    (fieldName) => {
      produceColumn((columnDraft) => {
        columnDraft.cellEditorParams.loadFromFieldName = fieldName;
      });
    },
    [produceColumn]
  );

  useEffect(() => {
    if (queries?.length && fieldName && !fieldOptions.length) {
      setFieldName(null);
    }
  }, [fieldName, fieldOptions.length, queries?.length, setFieldName]);

  const areThereFieldOptions = !!fieldOptions;
  const isFieldNameValid = !!selectedFieldOption;

  useEffect(() => {
    setColumnError(
      "loadFromQueryUuid",
      areThereFieldOptions
        ? null
        : `Please select a query for "Load from data".`
    );
  }, [areThereFieldOptions, setColumnError]);

  useEffect(() => {
    setColumnError(
      "loadFromFieldName",
      isFieldNameValid ? null : `Please select a field for "Load from data".`
    );
  }, [isFieldNameValid, setColumnError]);

  return (
    <Flex width="100%" gap="0.5rem">
      <div style={{ width: "20rem" }}>
        <Select
          isLoading={queriesLoading}
          options={queries}
          simpleValue={queryUuid}
          placeholder="Select a query..."
          getOptionLabel={(v) => v.name}
          getOptionValue={(v) => v.uuid}
          onChange={(v) =>
            produceColumn((columnDraft) => {
              columnDraft.cellEditorParams.loadFromQueryUuid = v.uuid;
            })
          }
        />
      </div>

      {fieldOptions ? (
        <div style={{ width: "20rem" }}>
          <Select
            options={fieldOptions}
            simpleValue={fieldName}
            placeholder="Select a field..."
            onChange={(v) => setFieldName(v.value)}
          />
        </div>
      ) : null}
    </Flex>
  );
}
